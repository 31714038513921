import React, { useEffect, useState } from "react";

const BsFooter = () => {
  const footerList = [
    {
      title: "Useful Links",
      list: [
        { title: "Home", link: "#" },
        { title: "About", link: "#" },
        { title: "Jets", link: "#" },
        { title: "Helicopters", link: "#" },
        { title: "Contacts", link: "#" },
      ],
    },
    {
      title: "Useful Links",
      list: [
        { title: "Home", link: "#" },
        { title: "About", link: "#" },
        { title: "Jets", link: "#" },
        { title: "Helicopters", link: "#" },
        { title: "Contacts", link: "#" },
      ],
    },
    {
      title: "Useful Links",
      list: [
        { title: "Home", link: "#" },
        { title: "About", link: "#" },
        { title: "Jets", link: "#" },
        { title: "Helicopters", link: "#" },
        { title: "Contacts", link: "#" },
      ],
    },
  ];
  return (
    <div className="bs-footer">
      <div className="uk-container">
        <div data-uk-grid>
          <div className="uk-width-1-1 uk-width-2-5@m">
            <h2>BrokerLogo</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Cupiditate illum in magnam maxime mollitia temporibus voluptatem!
              Assumenda at culpa facilis magnam magni nobis, quasi quisquam
              reiciendis sit tempora tenetur voluptas!
            </p>
            <div className="bs-icons">
              <span>
                <img
                  src="https://www.freepnglogos.com/uploads/logo-facebook-png/logo-facebook-best-facebook-logo-icons-gif-transparent-png-images-9.png"
                  alt="social"
                />
              </span>
              <span>
                <img
                  src="https://www.freepnglogos.com/uploads/logo-facebook-png/logo-facebook-best-facebook-logo-icons-gif-transparent-png-images-9.png"
                  alt="social"
                />
              </span>
              <span>
                <img
                  src="https://www.freepnglogos.com/uploads/logo-facebook-png/logo-facebook-best-facebook-logo-icons-gif-transparent-png-images-9.png"
                  alt="social"
                />
              </span>
              <span>
                <img
                  src="https://www.freepnglogos.com/uploads/logo-facebook-png/logo-facebook-best-facebook-logo-icons-gif-transparent-png-images-9.png"
                  alt="social"
                />
              </span>
            </div>
          </div>
          <div className="uk-width-1-1 uk-width-3-5@m">
            <div data-uk-grid className="uk-grid-small">
              {footerList.map((column, idx) => (
                <div
                  className="uk-width-1-1 uk-width-1-3@s"
                  key={column.title + idx}
                >
                  <h3>{column.title}</h3>
                  {column.list.map((item) => (
                    <div key={item.title + idx}>
                      <a className="bs-text-link" href={item.link}>
                        {item.title}
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BsFooter };
