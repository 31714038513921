import React from "react";
import { gallery, achievements, cards, miniCards, partners } from "./mockData";
import "uikit/dist/js/uikit";

const BsHeli = () => {
  return (
    <div className="bs-heli">
      <div className="uk-container">
        <div className="bs-banner">
          <div
            className="bs-banner-img"
            style={{
              background: `url(${"https://images.pexels.com/photos/2868214/pexels-photo-2868214.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}) center bottom / cover no-repeat`,
            }}
          />
          <div className="bs-banner-inner">
            <div>
              <h1>Its Time For New Adventures,Escapes thrills & experiences</h1>
            </div>
          </div>
        </div>
        <div className="uk-section uk-margin-large-top">
          <div className="uk-grid">
            {achievements.map((ach) => (
              <div key={ach.title} className="uk-width-1-1 uk-width-1-4@s">
                <div className="bs-ach">
                  <div className="bs-ach-inner">
                    <div className="bs-icon">
                      <img src={ach.imgSrc} alt="icon" />
                    </div>
                    <div>
                      <div className="bs-text bs-text-gold">{ach.value}</div>
                      <div className="bs-text">{ach.title}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id="getheli-iframe-base-element" />
        <div className="uk-section">
          <h2 className="uk-margin-large-bottom bs-section-title">
            Top rated Experiences on Travel
          </h2>
          <div className="bs-cards">
            <div className="uk-grid uk-grid-small">
              {cards.map((card) => (
                <div
                  key={card.title}
                  className="uk-width-1-1 uk-width-1-2@s uk-width-1-4@m"
                >
                  <div className="bs-card">
                    <img src={card.imgSrc} alt="img" />
                    <div className="bs-text">{card.text}</div>
                    <div className="bs-text bs-text-gold">{card.title}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="uk-section">
          <h2 className="uk-margin-large-bottom bs-section-title">
            Destinations near you
          </h2>
          <div data-uk-grid className="uk-grid">
            {miniCards.map((card, idx) => (
              <div
                key={card.title + idx}
                className="uk-width-1-1 uk-width-1-4@m"
              >
                <div className="bs-mini-card">
                  <div className="bs-mini-card-inner">
                    <div
                      className="img-box"
                      style={{
                        background: `url(${card.imgSrc}) center center / cover no-repeat`,
                      }}
                    />
                    <div className="bs-text">{card.title}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="uk-section">
          <h2 className="uk-margin-large-bottom bs-section-title">
            Destinations near you
          </h2>
          <div className="uk-grid uk-grid-small">
            <div className="uk-width-1-1 uk-width-1-2@m">
              <div className="bs-image-box">
                <div
                  className="bs-image-bg"
                  style={{
                    background: `url(${"https://images.pexels.com/photos/12795/pexels-photo-12795.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"}) center bottom / cover no-repeat`,
                  }}
                />
                <div className="bs-image-box-text">
                  <h4>Formula 1 / US </h4>
                  <span>The collection of the Department of US.</span>
                </div>
              </div>
            </div>
            <div className="uk-width-1-1 uk-width-1-2@m">
              <div
                data-uk-grid="masonry: true"
                className="uk-grid uk-grid-small uk-width-1-1 uk-child-width-1-2 uk-child-width-1-3@m"
              >
                {gallery.map((img) => (
                  <div key={img.imgSrc}>
                    <div
                      className="bs-gallery-card"
                      style={{ height: img.height }}
                    >
                      <div
                        className="bs-image-bg"
                        style={{
                          background: `url(${img.imgSrc}) center bottom / cover no-repeat`,
                        }}
                      />
                      <button className="bs-gallery-btn">view more</button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="uk-section">
          <h2 className="uk-margin-large-bottom bs-section-title">
            Our Partners
          </h2>
          <div className="uk-grid uk-grid-small">
            {partners.map((partner) => (
              <div key={partner.name} className="uk-width-1-1 uk-width-1-4@m">
                <div className="bs-partner">
                  <div className="bs-text">{partner.name}</div>
                  <div className="bs-partner-img">
                    <div
                      className="bs-image-bg"
                      style={{
                        background: `url(${partner.imgSrc}) center bottom / cover no-repeat`,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { BsHeli };
