export const jetCards = [
  {
    title: "Group Charter",
    imgSrc:
      "https://images.squarespace-cdn.com/content/v1/5c6fc253840b16c60dc0697b/1551285152847-ZM78J61E96LLVXFPBOAB/shutterstock_672416686.jpg?format=750w\n",
  },
  {
    title: "Private Jets",
    imgSrc:
      "https://images.squarespace-cdn.com/content/v1/5c6fc253840b16c60dc0697b/1551372488026-1BYL6GP1DQ0L9ZZJFYYV/shutterstock_1166866663.jpg?format=750w",
  },
  {
    title: "Helicopters",
    imgSrc:
      "https://images.pexels.com/photos/2868226/pexels-photo-2868226.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Freight",
    imgSrc:
      "https://images.squarespace-cdn.com/content/v1/5c6fc253840b16c60dc0697b/1551372009869-OCSJJNOQILMA3697S1X3/shutterstock_414384274.jpg?format=750w",
  },
];

export const achievements = [
  {
    value: "24,000",
    title: "Travel Experiences",
    imgSrc: "https://img.icons8.com/ios-glyphs/344/helicopter.png",
  },
  {
    value: "55+",
    title: "Countries",
    imgSrc: "https://img.icons8.com/material/344/globe--v2.png",
  },
  {
    value: "Best Price",
    title: "Guaranteed",
    imgSrc: "https://img.icons8.com/ios-glyphs/344/money--v1.png",
  },
  {
    value: "84 Million+",
    title: "User per year",
    imgSrc: "https://img.icons8.com/ios-filled/344/group-foreground-selected.png",
  },
];

export const cards = [
  {
    title: "Bangalore",
    text: " Beautiful luxury outdoor swimming pool hotel resort",
    imgSrc:
      "https://images.pexels.com/photos/2868232/pexels-photo-2868232.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Island",
    text: "Patio balcony with chair around sea ocean view",
    imgSrc:
      "https://images.pexels.com/photos/2868227/pexels-photo-2868227.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    title: "South East Asia",
    text: "athletic man floats red boat river",
    imgSrc:
      "https://images.pexels.com/photos/3757147/pexels-photo-3757147.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Himalayan",
    text: "Beautiful hikers top mountain view ",
    imgSrc:
      "https://images.pexels.com/photos/3003038/pexels-photo-3003038.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

export const miniCards = [
  {
    title: "Island",
    imgSrc:
      "https://images.pexels.com/photos/390051/surfer-wave-sunset-the-indian-ocean-390051.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Bangalore",
    imgSrc:
      "https://images.pexels.com/photos/7630190/pexels-photo-7630190.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Kabini",
    imgSrc:
      "https://images.pexels.com/photos/739987/pexels-photo-739987.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Coorg",
    imgSrc:
      "https://images.pexels.com/photos/9390722/pexels-photo-9390722.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Lonavala",
    imgSrc:
      "https://images.pexels.com/photos/655295/pexels-photo-655295.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    title: "Mumbai",
    imgSrc:
      "https://images.pexels.com/photos/10867565/pexels-photo-10867565.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    title: "Goa",
    imgSrc:
      "https://images.pexels.com/photos/4428272/pexels-photo-4428272.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    title: "Ooty",
    imgSrc:
      "https://images.pexels.com/photos/10883217/pexels-photo-10883217.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

export const gallery = [
  {
    height: 320,
    imgSrc:
      "https://images.pexels.com/photos/7640750/pexels-photo-7640750.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    height: 256,
    imgSrc:
      "https://images.pexels.com/photos/7902516/pexels-photo-7902516.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    height: 145,
    imgSrc:
      "https://images.pexels.com/photos/7339312/pexels-photo-7339312.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    height: 135,
    imgSrc:
      "https://images.pexels.com/photos/9959312/pexels-photo-9959312.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    height: 200,
    imgSrc:
      "https://images.pexels.com/photos/2880755/pexels-photo-2880755.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    height: 310,
    imgSrc:
      "https://images.pexels.com/photos/9899966/pexels-photo-9899966.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

export const partners = [
  {
    name: "Lara Nosworthy",
    imgSrc:
      "https://images.pexels.com/photos/676164/pexels-photo-676164.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    name: "Alan Baldessin",
    imgSrc:
      "https://images.pexels.com/photos/1337443/pexels-photo-1337443.png?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    name: "Emma and Fritzsche",
    imgSrc:
      "https://images.pexels.com/photos/5778296/pexels-photo-5778296.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
  },
  {
    name: "Joel Lynas",
    imgSrc:
      "https://images.pexels.com/photos/5778222/pexels-photo-5778222.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];
