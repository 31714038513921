import React from "react";
import Slider from "react-slick";
import "react-slick/dist/react-slick";
import { sliderSettings } from "./sliderSettings";
import { jetCards } from "./mockData";

const BsHome = () => {
  const titles = [
    { title: "Group Charter" },
    { title: "Private Jets" },
    { title: "Freight" },
    { title: "Helicopters" },
  ];

  return (
    <div className="bs-home">
      <div className="uk-container-expand">
        <div className="bs-banner">
          <div className="bs-banner-overlay" />
          <div
            className="bs-banner-img"
            style={{
              background: `url(${"https://images.squarespace-cdn.com/content/v1/5c6fc253840b16c60dc0697b/1551281197931-378CEPSWYN0KCPVE458D/shutterstock_417879718.jpg"}) center center / cover no-repeat`,
            }}
          />
          <div className="bs-banner-inner">
            <div>
              <h1>We deliver the extraordinary to fly your world</h1>
              <p>
                World-leading aviation services group, delivering a suite of
                solutions for you and your industry.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="uk-section bs-dark-section" id="Jets">
        <div className="uk-container">
          <div className="uk-text-center">
            <h2 className="uk-margin-bottom">Ready to fly?</h2>
            <div className="uk-grid">
              {titles.map((btn) => (
                <div key={btn.title} className="uk-width-1-1 uk-width-1-4@m">
                  <a
                    href="#"
                    className="uk-button uk-button-default bs-ready-button"
                  >
                    {btn.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="uk-section">
        <div className="uk-container">
          <div className="uk-text-center">
            <h2 className="uk-margin-large-bottom">
              At the core of every flight
            </h2>
            <div className="uk-grid">
              {Array.from({ length: 3 }).map((_, idx) => (
                <div key={idx} className="uk-width-1-1 uk-width-1-3@m">
                  <div className="uk-text-center">
                    <img
                      className="bs-icon"
                      src="https://ttrcoin.com/wp-content/uploads/2021/12/icon-icx-logo.png"
                      alt="icon"
                    />
                    <h3 className="uk-margin-small">Expertise</h3>
                    <p>
                      Our dedicated account managers go above and beyond to
                      create bespoke flights around you for a seamless
                      experience.
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="uk-section" id="Helicopters">
        <div className="uk-container">
          <div className="uk-text-center">
            <h2>Explore our services</h2>
            <Slider {...sliderSettings} className="bs-slider image-slider">
              {jetCards.map((card, idx) => (
                <div key={card.title} className="bs-slider-item">
                  <div className="bs-slider-item-overlay" />
                  <div
                    className="bs-slider-item-inner"
                    style={{
                      background: `url(${card.imgSrc}) center center / cover no-repeat`,
                    }}
                  >
                    <h3>{card.title}</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut blanditiis dolore dolorem est id impedit incidunt
                      inventore laborum magni minus, molestias.
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <hr />
      <div className="uk-section">
        <div className="uk-container">
          <div className="uk-text-center">
            <h2>What our customers say</h2>
            <Slider {...sliderSettings} className="bs-slider text-slider">
              {titles.map((card, idx) => (
                <div key={card.title + idx} className="bs-slider-item">
                  <div className="bs-slider-item-inner">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Aut blanditiis dolore dolorem est id impedit incidunt
                      inventore laborum magni minus, molestias.
                    </p>
                    <h5>{card.title}</h5>
                    <span>A leading partner</span>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BsHome };
