import React, { useEffect, useState } from "react";

const menuList = [
  { title: "Home", active: true },
  { title: "About", active: false },
  { title: "Jets", active: false },
  { title: "Helicopters", active: false },
  { title: "Contacts", active: false },
];

const BsHeader = ({ changePage }) => {
  const [menu, setMenu] = useState(menuList);

  const changeActive = (idx) => {
    const list = [...menuList];
    list.map((item) => (item.active = false));
    list[idx].active = true;
    return setMenu(list);
  };

  const scrollFunc = (id) => {
    if (id === "Home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      changeActive(0);
      return changePage("1");
    }
    if (id === "Helicopters") {
      window.scrollTo({ top: 0, behavior: "smooth" });
      changeActive(3);
      return changePage("2");
    }
    const el = document.getElementById(id);
    const stickyHeaderHeight = 80;
    const section = el.offsetTop - stickyHeaderHeight;
    window.scrollTo({ top: section, behavior: "smooth" });
  };

  return (
    <div
      className="bs-header"
      data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
    >
      <div className="uk-container">
        <nav
          className="uk-background-default uk-navbar"
          data-uk-navbar="mode: hover; align: right;"
        >
          <div className="uk-navbar-left">
            <a href="" className="uk-navbar-item uk-logo">
              BlueSky
            </a>
          </div>
          <div className="uk-navbar-right">
            <ul className="uk-navbar-nav uk-visible@m">
              {menu.map((item) => (
                <li key={item.title} className={item.active ? "uk-active" : ""}>
                  <a
                    href={`${item.title.toLowerCase()}`}
                    onClick={() => scrollFunc(item.title)}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
            <div className="uk-navbar-nav uk-hidden@m">
              <button
                className="uk-navbar-toggle uk-icon"
                data-uk-toggle="target: #bs-offcanvas"
              >
                <svg
                  width="28"
                  height="20"
                  viewBox="0 0 28 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 9.99993H26.7143"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 1.42852H26.7143"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1 18.5713H26.7143"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <div
                id="bs-offcanvas"
                data-uk-offcanvas="mode: slide; flip: true; overlay: true"
              >
                <div className="uk-offcanvas-bar">
                  <ul className="uk-nav uk-nav-default">
                    {menu.map((item) => (
                      <li
                        key={item.title}
                        className={item.active ? "uk-active" : ""}
                      >
                        <a onClick={() => scrollFunc(item.title)}>
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export { BsHeader };
