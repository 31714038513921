import React, { useEffect, useState } from "react";
import { BsHeader } from "./bs-header";
import { BsHome } from "./bs-home";
import { BsFooter } from "./bs-footer";
import { BsHeli } from "./bs-heli";
import "./bsStyles.css";

const BrokerWpComponent = () => {
  const [page, setPage] = useState("1");

  useEffect(() => {
    if (window.location.pathname.includes("helicopters")) {
      return setPage("2");
    }
  }, []);

  return (
    <div className="broker-bs">
      <BsHeader changePage={setPage} />
      {page === "1" ? <BsHome /> : <BsHeli />}
      <BsFooter />
    </div>
  );
};

export { BrokerWpComponent };
