import { BrokerWpComponent } from "./some";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrokerWpComponent />
    </div>
  );
}

export default App;
